<template>
  <div class="py-6">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "profile-route-wrapper"
};
</script>
